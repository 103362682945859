import { Button, CircularProgress, Container, Grid, Typography } from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import fetch from 'react-storefront/fetch';
import Link from "next/link";
import {useEffect, useState} from "react";
import Image from "next/image";
import ProductPagination from "../../../navigation/pagination";
import {useTranslation} from "tools/i18n";
import {useRouter} from "next/router";
import {Alert} from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../theme";
import { getCMSSettings } from "../../../../tools/cmsBlockSettings";

const useStyles = makeStyles((theme) => {
	return {
		wrapper: {

		},
		grid: {
			display: 'grid',
			gridTemplateColumns: '1fr 1fr 1fr',
			gridColumnGap: 30,
			gridRowGap: 30,
			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: '1fr'
			}
		},
		itemWrapper: {
			display: 'grid',
			gridRowTemplates: '1fr 1fr',
			gridRow: 'span 2',
			'&.large': {
				'& $description': {
					'&::after': {
						[theme.breakpoints.up('sm')]: {
							//display: 'none'
						}
					}
				}
			},
			[theme.breakpoints.up('sm')]: {
				'&.large': {
					gridColumn: 'span 2',
					'& $item': {
						gridTemplateRows: '1fr 1fr',
					}
				},
				'&.small': {
					gridRow: 'span 1'
				},
			},
			[theme.breakpoints.down('sm')]: {
				'&.large': {
					gridColumn: 'span 1'
				},
			}
		},
		item: {
			display: 'grid',
			gridTemplateRows: '1fr 1fr',
			'&:link': {
				textDecoration: 'none'
			},
			[theme.breakpoints.down('sm')]: {
				gridTemplateRows: '250px 300px'
			}
		},
		imageWrapper: {
			position: 'relative',
			[theme.breakpoints.up('md')]: {
				'&.big': {
					//height: 480
				},
				'&.small': {
					height: 240
				}
			}
		},
		info: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '50px 30px',
			color: theme.palette.primary.lowlight,
			textAlign: 'center',
			textDecoration: 'none',
			backgroundColor: theme.palette.primary.background
		},
		preTitle: {
			fontFamily: theme.typography.fontFamily,
			letterSpacing: 2,
			color: '#999',
			textTransform: 'uppercase'
		},
		title: {
			marginBottom: 10,
			fontSize: 24,
			color: "#333"
		},
		description: {
			position: 'relative',
			maxWidth: 550,
			maxHeight: 88,
			fontSize: 16,
    		lineHeight: 1.8,
			color: '#666',
			overflow: 'hidden',
			'&::after': {
				content: '""',
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0,
				height: 60,
				background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))'
			},
			[theme.breakpoints.up('md')]: {

			}
		},
		readMore: {
			marginTop: 10,
			padding: '8px 16px',
			textTransform: 'none',
			textDecoration: 'underline',
			fontWeight: 600,
			'&:hover': {
				backgroundColor: 'transparent',
				textDecoration: 'underline'
			},
			[theme.breakpoints.up('sm')]: {
				display: 'none'
			}
		}
	}
}, {name: 'MainFeed'});

export default function MainFeed({block, category}) {
	const router = useRouter();
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const [articles, setArticles] = useState(false);
	const [pagination, setPagination] = useState(false);
	const {t} = useTranslation();

	useEffect(async () => {
		await fetchArticles();
	}, [router.asPath]);

	async function fetchArticles() {
		let mainFeedRes = await fetch('/api/inspiration/getMainFeed', {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				page: router.query.page && router.query.page > 1 ? router.query.page : 1,
				parentId: category.id,
				maxLevel: category.level + 3
			})
		});

		let mainFeedResults = await mainFeedRes.json();

		setArticles(mainFeedResults.articles);
		setPagination(mainFeedResults.pagination);
	}

	function getUrl(article) {
		return article.seoUrls ? '/' + article.seoUrls[0].seoPathInfo : '/navigation/' + article.id;
	}

	function renderImage(article, index) {
		let image = article.translated?.customFields?.inspiration_home_image;


		let additional = {};
		additional.layout = "fill";
		additional.objectFit = "cover";
		additional.alt = article.translated?.customFields?.inspiration_home_title;

		return image ? <Image src={image.url} {...additional} unoptimized/> : false;
	}

	function onPaginate(event, page) {
		router.push({
			pathname: router.asPath.split('?')[0],
			query: {
				page: page
			}
		});
	}

	return (
		<Container style={{
			...CMSSettings.styles
		}} maxWidth="xl" disableGutters>
			<Grid className={classes.grid} container>
				{articles ? articles.length > 0 ? articles.filter((article) => article.translated?.customFields && renderImage(article)).map((article, index) =>
					<Grid className={[classes.itemWrapper, 'small'].join(' ')} key={index} item>
						<Link href={getUrl(article)}>
							<a className={classes.item}>
								<div className={[classes.imageWrapper, 'small'].join(' ')}>
									{renderImage(article, index)}
								</div>
								<div className={classes.info}>
									<span className={classes.preTitle}>{t('inspiration')}</span>
									<Typography variant="h3" className={classes.title}>{article.translated?.customFields.inspiration_home_title}</Typography>
									<Typography className={classes.description}>{article.translated.description}</Typography>

									<Button className={classes.readMore}>{t('read-more-inspiration')} »</Button>
								</div>
							</a>
						</Link>
					</Grid>
				) : <Alert severity="warning" style={{gridColumn: '1/4', justifyContent: 'center'}}>{t('empty-inspiration')}</Alert> : ''}
				{!articles ? <CircularProgress style={{gridColumn: '1/4', justifySelf: 'center'}}/> : ''}
			</Grid>
			<Grid xs={12} item>
				{pagination ? <ProductPagination currentPage={pagination.page} productPerPage={pagination.limit} maxPage={Math.ceil(pagination.total / pagination.limit)} onChange={onPaginate}/> : ''}
			</Grid>
		</Container>
	);
}