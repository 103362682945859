import { makeStyles } from "@material-ui/styles";
import theme from "../../../theme";
import { getCMSSettings, getCMSSlot } from "tools/cmsBlockSettings";
import React from "react";
import { useTranslation } from "../../../../tools/i18n";
import TextContent from "../text/text-content";

const useStyles = makeStyles({
    wrapper: {},
    mapWrapper: {
        position: 'relative'
    },
    map: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 'none'
    },
    mapInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        '& a': {
            color: 'inherit',
            fontSize: 12,
            [theme.breakpoints.up('sm')]: {
                fontSize: 14
            }
        }
    },
    mapAddress: {
        marginTop: 5,
        maxWidth: 150,
        '& div, & p, & a': {
            lineHeight: '1.5 !important',
            color: '#000'
        }
    },
    title: {
        margin: 0,
        fontSize: 12,
        fontWeight: 500,
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14
        }
    },
}, { name: 'GoogleMaps' });

export default function GoogleMaps({ slots, block }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const CMSSettings = getCMSSettings(block);
    const slot = getCMSSlot('googleMaps', slots);

    const apiKey = 'AIzaSyDJQbqjgLZFsYrEek7wwidkmt8Gx3itbZ0';

    function getMapSource() {
        let addressQuery = encodeURIComponent(slot.config.address.value || '');
        return 'https://www.google.com/maps/embed/v1/place?key=' + apiKey + '&q=' + addressQuery;
    }

    function getGoogleMapsLink() {
        let addressQuery = encodeURIComponent(slot.config.address.value || '');
        return 'https://www.google.com/maps/search/?api=1&query=' + addressQuery;
    }

    function getAspectRatioPadding() {
        let aspectWidth = slot.config.aspectWidth.value || 16;
        let aspectHeight = slot.config.aspectHeight.value || 16;

        return (aspectHeight / aspectWidth) * 100;
    }

    return (
        <div style={{
            ...CMSSettings.styles,
        }}
             className={[classes.wrapper, block.cssClass].join(' ')}
        >
            <div className={classes.mapWrapper} style={{paddingBottom: getAspectRatioPadding() + '%'}}>
                <iframe
                    referrerpolicy="no-referrer-when-downgrade"
                    src={getMapSource()}
                    className={classes.map}
                    allowfullscreen></iframe>
            </div>

            <div className={classes.mapInfo}>
                <div>
                    <h4 className={classes.title}>{t('store-address')}</h4>
                    <TextContent content={slot.config.address.value} className={classes.mapAddress} newStyling={true}/>
                </div>
                <a href={getGoogleMapsLink()} target={"_blank"} className={classes.googleMapsLink}>{t('google-maps-open')}</a>
            </div>
        </div>
    );
}
