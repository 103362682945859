import { makeStyles } from "@material-ui/styles";
import theme from "../../../theme";
import Image from "next/image";
import { getCMSSettings, getCMSSlot } from "tools/cmsBlockSettings";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../../tools/i18n";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import SearchIcon from "public/images/icons/search-thin.svg";
import ExpandMore from "public/images/icons/expand_more_light.svg";
import { useRouter } from "next/router";
import Link from "next/link";

const useStyles = makeStyles({
    wrapper: {},
    title: {
        marginBottom: 30,
        fontSize: 28,
        fontWeight: 400,
        fontFamily: 'Chronicle, "Times New Roman", serif',
        textTransform: 'uppercase'
    },
    storeList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 40,
        [theme.breakpoints.down('sm')]: {
            gap: 20
        }
    },
    storeItem: {
        marginBottom: 20,
        width: 'calc(33.333% - 26.666px)',
        color: 'inherit',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    storePreTitle: {
        margin: '4px 0 0',
        fontSize: 12,
        textTransform: 'uppercase'
    },
    storeTitle: {
        margin: '2px 0 0',
        fontSize: 26,
        fontFamily: 'Chronicle, "Times New Roman", serif',
        fontWeight: 400,
        textTransform: 'uppercase'
    },
    actions: {
		display: 'flex',
		justifyContent: 'space-between',
        marginBottom: 40
    },
    selectWrapper: {
        height: 38,
        padding: '10px 12px',
        backgroundColor: theme.palette.primary.background,
		borderRadius: 0,
        border: '1px solid #888',
    },
    select: {
        padding: 0,
		borderRadius: 0,
        border: 'none',
        '&:focus': {
            backgroundColor: 'transparent'
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 200,
        }
    },
    actionRoot: {
        [theme.breakpoints.down('sm')]: {
            width: 'calc(50% - 5px)'
        }
    },
    selectLabel: {
        transform: 'translate(12px, 12px) scale(1)',
        color: theme.palette.primary.lowlight,
        fontSize: 14
    },
    selectIcon: {
        marginRight: -16,
        color: theme.palette.primary.lowlight,
        transform: 'scale(0.66666)',
        flexShrink: 0
    },
    inputWrapper: {
        padding: '10px 12px',
        border: '1px solid #888',
        borderRadius: 0,
        backgroundColor: theme.palette.primary.background,
    },
    input: {
        padding: 0,
        height: 16,
        borderRadius: 0,
        color: theme.palette.primary.lowlight,
        fontSize: 14,
        '&::placeholder': {
            opacity: 1,
            color: theme.palette.primary.lowlight,
            fontSize: 14,
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 200
        }
    },
}, { name: 'StoreBrowser' });

export default function StoreBrowser({ slots, block }) {
    const classes = useStyles();
	const router = useRouter();
    const { t } = useTranslation();
    const CMSSettings = getCMSSettings(block);
    const slot = getCMSSlot('storeBrowser', slots);
    const [selectedStore, setSelectedStore] = useState("");
    const [storeQuery, setStoreQuery] = useState("");

	useEffect(() => {
		let store = getStores()[selectedStore];
		if(store) {
			let url = getStoreUrl(store);

			if(url) {
				router.push(url);
			}
		}
	}, [selectedStore]);

    function getStores() {
        let stores = slot.data?.stores;

        return stores ? stores.filter((store) => store.active).filter((store) => {
            if(storeQuery.length <= 0) {
                return true;
            }

            let lowerCaseQuery = storeQuery.toLowerCase();
            let storeName = getStoreName(store).toLowerCase();
            let storePreTitle = getStorePreTitle(store).toLowerCase();

            return storeName.indexOf(lowerCaseQuery) >= 0 || storePreTitle.indexOf(lowerCaseQuery) >= 0;
        }) : [];
    }

    function getImage(store) {
        const image = store.translated?.customFields?.stores_image;

        if(!image) {
            return {
                src: '/images/placeholder/product.jpg',
                width: 0,
                height: 0
            }
        }

        return {
            src: image.url,
            width: image.metaData.width,
            height: image.metaData.height
        };
    }

    function getStorePreTitle(store) {
        return store.translated?.customFields?.stores_pretitle;
    }

    function getStoreName(store) {
        return store.translated?.customFields?.stores_title || store.translated?.name;
    }

    function getStoreUrl(store) {
        if(!store.seoUrls || store.seoUrls.length <= 0) {
            return '/navigation/' + store.id;
        }

        return '/' + store.seoUrls[0]?.seoPathInfo
    }

    function selectIcon() {
        return <ExpandMore className={classes.selectIcon} width={48} height={48}/>;
    }

    return (
        <div style={{
            ...CMSSettings.styles,
        }}
             className={[classes.wrapper, block.cssClass].join(' ')}
        >
            <h2 className={classes.title}>{t('our-stores')}</h2>

            <div className={classes.actions}>
                <TextField
                    variant={'filled'}
                    size={'small'}
                    placeholder={t('store-search')}
                    value={storeQuery}
                    classes={{root: classes.actionRoot}}
                    inputProps={{ className: classes.input }}
                    InputProps={{
                        classes: {root: classes.inputWrapper},
                        disableUnderline: true,
                        endAdornment: <SearchIcon width={18} />
                    }}
                    onChange={event => setStoreQuery(event.target.value)}
                />

                <FormControl classes={{root: classes.actionRoot}}>
                    <InputLabel
                        id={"store-browser-selector"}
                        style={{ zIndex: 10, pointerEvents: 'none' }}
                        classes={{ root: classes.selectLabel }}
                    >{t('select-store')}</InputLabel>
                    <Select
                        labelId={"store-browser-selector"}
                        value={selectedStore}
                        classes={{
                            select: classes.select
                        }}
                        IconComponent={selectIcon}
						onChange={(event) => setSelectedStore(event.target.value)}
						disableUnderline={true}
                        className={classes.selectWrapper}
                        autoWidth={true}
                        variant={"filled"}>
                        {getStores().map((store, index) => <MenuItem value={index} key={index}>{getStoreName(store)}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>

            <div className={classes.storeList}>
                {getStores().map((store, index) => {
                    return (
                        <Link href={getStoreUrl(store)} key={index}>
                            <a className={classes.storeItem}>
                                <Image {...getImage(store)} unoptimized/>
                                <p className={classes.storePreTitle}>{getStorePreTitle(store)}</p>
                                <h2 className={classes.storeTitle}>{getStoreName(store)}</h2>
                            </a>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}
