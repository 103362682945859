import { makeStyles } from "@material-ui/styles";
import { getCMSSettings, getCMSSlot } from "tools/cmsBlockSettings";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../../tools/i18n";
import ProductItem from "../../../products/item";
import {
    calculateProductWidth,
    getActiveVariant,
    getIsAvailable, getMarketingLabels,
    getProductPrices,
    getProductUrl,
    getVariants
} from "../../../../functions/productOps";
import ProductThumbnail from "../../../products/thumbnail";
import { Button, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    wrapper: {},
    categoryButtons: {
        display: 'flex',
        gap: 10,
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    categoryButton: {
        padding: '15px 25px',
        width: 'calc(25% - 7.25px)',
        borderRadius: 0,
        backgroundColor: '#f0f0f0',
        color: 'black',
        '&:hover': {
            color: theme.palette.primary.background,
            backgroundColor: theme.palette.primary.color
        },
        '&.active': {
            color: theme.palette.primary.background,
            backgroundColor: '#000',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(50% - 10px)'
        }
    }
}), { name: 'StoreProducts' });

export default function StoreProducts({ slots, block, category }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const CMSSettings = getCMSSettings(block);
    const slot = getCMSSlot('storeProducts', slots);
    const [products, setProducts] = useState([]);
    const [filterCategory, setFilterCategory] = useState(false);
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        let store = slot.config.store?.value;
        let categoryIds = [];
        if(filterCategory) {
            categoryIds = [filterCategory];
        } else if (slot.config.categories.value) {
            categoryIds = slot.config.categories.value;
        }

        if(store) {
            fetchProducts(store, categoryIds, isDesktop ? 8 : 4);
        }
    }, [filterCategory]);

    async function fetchProducts(store, categoryIds, count) {
        let categoryIdString = categoryIds.length > 0 ? '/' + categoryIds.join(',') : '';
        let result = await fetch('/api/product/get/by-store/' + store + categoryIdString + '?count='+count, {
            headers: {
                'Accepts': 'application/json'
            }
        });

        let resultJson = await result.json();
        if(resultJson.status === 'success') {
            setProducts(resultJson.products);
        }
    }

    function renderProducts(sizes) {
		return products.length > 0
			? products.map((product, key) => (
					<ProductItem
						id={product.id}
						productNumber={product.productNumber}
						name={product.translated.name}
						link={getProductUrl(product)}
						prices={getProductPrices(product)}
						image={<ProductThumbnail image={product.cover} alt={product.translated.name} size={550} />}
						list={category.translated.name}
						manufacturer={product.manufacturer ? product.manufacturer.translated.name : ''}
						available={getIsAvailable(product)}
						variants={getActiveVariant(product) ? getVariants(product) : []}
						activeVariant={getActiveVariant(product)}
						labels={getMarketingLabels(product)}
						fastDelivery={
							!!(
								product.translated.customFields.fast_delivery_available &&
								config.language.locale === 'sv'
							)
						}
						position={key + 1}
						sizes={sizes}
						lazyHydrate={false}
						key={`${product.parentId}-${product.id}-${key}`}
					/>
			  ))
			: '';
	}

    return (
        <div style={{
            ...CMSSettings.styles,
        }}
             className={[classes.wrapper, block.cssClass].join(' ')}
        >
            <div className={classes.categoryButtons}>
                {slot.data.categories.map((category) => {
                    return <Button className={[classes.categoryButton, filterCategory ? (filterCategory === category.id ? 'active' : '') : 'active']} onClick={() => setFilterCategory(category.id)}>
                        {category.translated.name}
                    </Button>
                })}
            </div>

            <Grid
                container
                item
                direction="row"
                justify="center"
                alignItems="stretch"
                spacing={1}
                style={{
                    ...CMSSettings.styles
                }}
            >
                {renderProducts(calculateProductWidth(products))}
            </Grid>
        </div>
    );
}
