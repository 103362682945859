import { makeStyles } from '@material-ui/styles';
import formatPrice from '../../../../functions/priceFormat';
import { useContext } from 'react';
import { SessionContext } from '../../../providers/session';
import theme from '../../../theme';

const useStyles = makeStyles({
    promotionPriceWrapper: {
        display: 'flex',
        alignItems: 'center',
        margin: '16px auto 0',
        padding: '8px 16px',
        gap: 8,
        color: 'white',
        background: '#111',
        width: 'fit-content',
        justifyContent: 'center',
        fontFamily: 'Chronicle, "Open Sans", sans-serif',
        '.blackfriday &': {
            color: 'white',
            background: 'transparent',
            border: '1px solid #fff',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            margin: '16px 0 0',
        },
    },
    promotionPrice: {
        fontSize: 18,
    },
});

export default function PromotionPrice(
    {
        available,
        streamIds = [],
        prices,
    }
) {
    const session = useContext(SessionContext);
    const classes = useStyles();

    if (!session.isSaleOngoing) {
        return null;
    }

    function calculate(discount, prices) {
        if (prices?.listPrice > prices?.price) {
            return '';
        }

        const originalPrice = prices?.price;
        return formatPrice((originalPrice - (originalPrice * (discount / 100))));
    }

    function renderLabel(label) {
        return <>
            <span className>{label.label}</span>
            <span className={classes.promotionPrice}>{calculate(label.value, prices)}</span>
        </>
    }

    function renderStreamIds(productStreamIds) {
        if (!Array.isArray(productStreamIds) || !(productStreamIds.length > 0)) {
            return null;
        }


        const labels = {
            '01931b126f317ed5965082b389598a6a': {value: 70, label: 'With Pre-Access'},
            '01931b1561be719cb5a9f0f94c40f6d3': {value: 50, label: 'With Pre-Access'},
            '01931babf0667a4c8da1d8e9fde47ab6': {value: 20, label: 'With Pre-Access'},
        };

        if (!session.actions.isSalesChannelNordic()) {
            return null;
        }

        if (prices.discount) {
            return null;
        }

        if (!available) {
            return null;
        }

        if (productStreamIds.includes('01931b126f317ed5965082b389598a6a')) { // 70%
            return renderLabel(labels['01931b126f317ed5965082b389598a6a']);
        }

        if (productStreamIds.includes('01931b1561be719cb5a9f0f94c40f6d3')) { // 50%
            return renderLabel(labels['01931b1561be719cb5a9f0f94c40f6d3']);
        }

        if (productStreamIds.includes('01931babf0667a4c8da1d8e9fde47ab6')) { // 20%
            return renderLabel(labels['01931babf0667a4c8da1d8e9fde47ab6']);
        }

        return null;
    }

    const label = renderStreamIds(streamIds);
    if (!label) {
        return null;
    }

    return <div className={classes.promotionPriceWrapper}>
        { label }
    </div>
}
