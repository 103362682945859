import { useEffect, useState } from 'react';
import blockList from "./blocks/cmsBlockList";
import {getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import moment from 'moment';
import 'moment-timezone';
import {Grid} from "@material-ui/core";
import LazyHydrate from "tools/lazyHydrate";
import {makeStyles} from "@material-ui/styles";
import Countdown from "./blocks/misc/countdown";

const useStyles = makeStyles((theme) => ({
	block: {
		'&:empty': {
			display: 'none'
		},
		'&.hideMobile': {
			[theme.breakpoints.down('xs')]: {
				display: 'none'
			}
		},
		'&.hideTablet': {
			[theme.breakpoints.between('sm', 'md')]: {
				display: 'none'
			}
		},
		'&.hideDesktop': {
			[theme.breakpoints.up('lg')]: {
				display: 'none'
			}
		}
	}
}), {name: 'CmsBlock'});

function Block({block, cmsPage, breadcrumbs, category, categoryMenu, section, key}) {
	const classes = useStyles();
	const displaySettings = getCMSSlot('displaySettings', block.slots);
	const CMSSettings = getCMSSettings(block);
	const blockId = block.slots?.length > 0 ? block.slots[0].blockId : key;
	const [now, setNow] = useState(new Date());

	useEffect(() => {
		setNow(new Date());
	}, []);

	function renderBlockType() {
		if(Object.keys(blockList).indexOf(block.type) >= 0) {
			let BlockType = blockList[block.type];

			return <BlockType
				block={block}
				isFullWidth={section.sizingMode === 'full_width'}
				slots={block.slots}
				cmsPage={cmsPage}
				breadcrumbs={breadcrumbs}
				category={category}
				categoryMenu={categoryMenu}
			/>;
		} else {
			return '';
		}
	}

	function isInsideDateRange(slot) {
		let config = slot?.translated?.config || slot?.config;

		if(!config?.fromDate?.value && !config?.toDate?.value) {
			return true;
		}

		//let fromDate = config?.fromDate.value ? new Date(config?.fromDate?.value) : null;
		//let toDate = config?.toDate.value ? new Date(config?.toDate?.value) : null;
		let fromDate = moment(config?.fromDate.value).tz('Europe/Stockholm');
		let toDate = moment(config?.toDate.value).tz('Europe/Stockholm');

		if(fromDate && fromDate < now) {
			if(!toDate || (toDate && toDate > now)) {
				return true;
			} else {
				return false;
			}
		} else if (!fromDate && toDate > now) {
			return true;
		} else {
			return false;
		}

		return true;
	}

	function hasDateRangeSet() {
		if(!displaySettings) {
			return false;
		}

		let config = displaySettings?.translated?.config || displaySettings?.config;

		return config?.fromDate?.value || config?.toDate?.value;
	}

	function shouldShowCountdown(slot) {
		if(!slot || slot?.translated?.config?.showCountdown?.value !== true) {
			return false;
		}

		let config = slot?.translated?.config || slot?.config;

		let fromDate = config?.fromDate.value ? new Date(config?.fromDate?.value) : null;

		if(fromDate && now < fromDate) {
			return fromDate;
		} else {
			return false;
		}

		return false;
	}

	function getSlotsIsActive() {
		let active = true;

		block.slots.forEach((slot) => {
			let slotActive = slot?.translated?.config?.active?.value;

			if(slot.slot === 'campaignEntry' && typeof slotActive !== 'undefined' && slotActive === false) {
				active = false;
			}
		});

		if(hasDateRangeSet() && !shouldShowCountdown(displaySettings) && !isInsideDateRange(displaySettings)) {
			active = false;
		}

		return active;
	}

	function getGridSettings() {
		let gridSettings = {
			xs: 12
		}

		if(section.cssClass && section.cssClass.indexOf('campaign') >= 0) {
			gridSettings.md = 6;
			gridSettings.lg = 4;
		}

		if(section.cssClass && section.cssClass.indexOf('slim-aarons') >= 0) {
			gridSettings.md = 6;
			gridSettings.lg = 6;
		}

		return gridSettings;
	}

	function getVisibilityClasses() {
		let hideClasses = [];

		if(CMSSettings.visibility && !CMSSettings.visibility.mobile) {
			hideClasses.push('hideMobile');
		}

		if(CMSSettings.visibility && !CMSSettings.visibility.tablet) {
			hideClasses.push('hideTablet');
		}

		if(CMSSettings.visibility && !CMSSettings.visibility.desktop) {
			hideClasses.push('hideDesktop');
		}

		return hideClasses;
	}

	return (
		<Grid id={block.name} className={[classes.block, ...getVisibilityClasses()].join(' ')} {...getGridSettings()} item>
			{getSlotsIsActive() ?
				<LazyHydrate id={'LazyCMS-' + blockId} on="visible">
					{ hasDateRangeSet() ? (
						shouldShowCountdown(displaySettings) ?
							<Countdown block={block} later={shouldShowCountdown(displaySettings)}/> : (isInsideDateRange(displaySettings) ? renderBlockType() : '')
					) : renderBlockType() }
				</LazyHydrate> : ''}
		</Grid>
	);
}

export default Block;
