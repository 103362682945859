import { Grid } from '@material-ui/core';
import { getCMSSettings, getCMSSlot } from 'tools/cmsBlockSettings';
import {
	getVariants,
	getProductUrl,
	getProductPrices,
	getIsAvailable,
	getMarketingLabels,
	calculateProductWidth,
	getCategoryBreadcrumb
} from 'functions/productOps';
import React, { useContext, useEffect, useRef } from 'react';
import ProductItem from '../../../products/item';
import ProductThumbnail from '../../../products/thumbnail';
import { SessionContext } from '../../../providers/session';
import SlickWrapper from "../../../slick/productSlider";
import theme from "../../../theme";
import { makeStyles } from "@material-ui/styles";
import { preventTouch, touchStart } from "../../../../functions/touchHelpers";

const useStyles = makeStyles(
	{
		wrapper: {
			[theme.breakpoints.down('xs')]: {
				marginBottom: '0px !important'
			}
		},
	},
	{ name: 'NewportProductBlock' }
);

export default function NewportProductBlock({ block, slots, breadcrumbs, category }) {
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block, 0);
	const elem = useRef();
	const productsData = getCMSSlot('productSlider', slots);
	const { config } = useContext(SessionContext);
	const isStatic = productsData.config.products.source === 'static';
	const visibleProductsCount = productsData.config.visibleProductsCount
		? productsData.config.visibleProductsCount.value
		: 0;
	let products = productsData.data.products;

	// Show specific count if they're manually selected and not set to show all
	if (isStatic && visibleProductsCount !== 0) {
		products = products.slice(0, visibleProductsCount);
	}

	useEffect(() => {
		if(elem.current) {
			elem.current.addEventListener('touchstart', touchStart);
			elem.current.addEventListener('touchmove', preventTouch, { passive: false });
		}

		return () => {
			if(elem.current) {
				elem.current.removeEventListener('touchstart', touchStart);
				elem.current.removeEventListener('touchmove', preventTouch, { passive: false });
			}
		}
	}, [elem]);

	function renderProducts(sizes) {
		let categoryBreadcrumbs = getCategoryBreadcrumb(breadcrumbs, 1);

		if(categoryBreadcrumbs.length < 1) {
			categoryBreadcrumbs = [category.translated.name];
		}

		return products.length > 0 ? products.map((product, key) => (
				<ProductItem
					id={product.id}
					productNumber={product.productNumber}
					name={product.translated.name}
					link={getProductUrl(product)}
					prices={getProductPrices(product)}
					image={<ProductThumbnail image={product.cover} size={550} alt={product.translated.name} />}
					categoryBreadcrumb={categoryBreadcrumbs}
					list={"CMS Product Block"}
					manufacturer={product.manufacturer ? product.manufacturer.translated.name : ''}
					available={getIsAvailable(product)}
					variants={getVariants(product)}
					labels={getMarketingLabels(product)}
					stock={product.availableStock}
					deliveryTimeString={product.deliveryTime}
					fastDelivery={
						!!(
							product.translated.customFields.fast_delivery_available &&
							config.language.locale === 'sv'
						)
					}
					addToCart={'minimal'}
					position={key + 1}
					sizes={sizes}
					lazyHydrate={false}
					key={`${product.parentId}-${product.id}-${key}`}
					properties={product.propertyIds}
					streamIds={product.streamIds}
				/>
		  ))
		: '';
	}

	function isSlider() {
		return !!productsData?.config?.slider?.value;
	}

	return (
		<>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="stretch"
				spacing={1}
				className={classes.wrapper}
				style={{
					...CMSSettings.styles
				}}
			>
				{isSlider() ?
					<Grid xs={12} ref={elem} item><SlickWrapper>{renderProducts({xs: 12, sm: 12, md: 12, lg: 12, xl: 12})}</SlickWrapper></Grid> : renderProducts(calculateProductWidth(products))}
			</Grid>
		</>
	);
}
