import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import TextContent from "../text/text-content";
import {getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";

const useStyles = makeStyles((theme) => ({
	content: {
	},
	item: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
		'.one-column-mobile &': {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				maxWidth: '100%',
				flexBasis: '100%'
			}
		}
	}
}), {name: 'TextTwoColumns'});

export default function TextTwoColumns({slots, block}) {
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const left = getCMSSlot('left', slots);
	const right = getCMSSlot('right', slots);

	return (
		<Grid spacing={2} className={[classes.content, CMSSettings.class].join(' ')} style={{
				...CMSSettings.styles,
			}} container>
			<Grid xs={6} md={6} classes={{root: classes.item}} item>
				<TextContent content={left.translated.config.content.value}/>
			</Grid>

			<Grid xs={6} md={6} classes={{root: classes.item}} item>
				<TextContent content={right.translated.config.content.value}/>
			</Grid>
		</Grid>
	);
}
