import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import React, {useEffect, useState} from "react";
import moment from 'moment';
import 'moment-timezone';
import {Button, Grid, Grow, Slide} from "@material-ui/core";
import {useTranslation} from "tools/i18n";

const useStyles = makeStyles({
    "@keyframes shake": {
        "2%": {
            transform: "translate(5px, 3px) rotate(-3deg)"
        },
        "4%": {
            transform: "translate(5px, 1px) rotate(-3deg)"
        },
        "6%": {
            transform: "translate(5px, 1px) rotate(3deg)"
        },
        "8%": {
            transform: "translate(5px, 5px) rotate(3deg)"
        },
        "10%": {
            transform: "translate(3px, -3px) rotate(-3deg)"
        },
        "12%": {
            transform: "translate(-3px, 1px) rotate(3deg)"
        },
        "14%": {
            transform: "translate(1px, 3px) rotate(-3deg)"
        },
        "16%": {
            transform: "translate(-3px, 1px) rotate(3deg)"
        },
        "18%": {
            transform: "translate(3px, -3px) rotate(3deg)"
        },
        "20%": {
            transform: "translate(5px, 3px) rotate(-3deg)"
        },
        "22%": {
            transform: "translate(1px, -3px) rotate(3deg)"
        },
        "24%": {
            transform: "translate(1px, -1px) rotate(-3deg)"
        },
        "26%": {
            transform: "translate(1px, -3px) rotate(3deg)"
        },
        "28%": {
            transform: "translate(1px, 1px) rotate(3deg)"
        },
        "30%": {
            transform: "translate(5px, 1px) rotate(3deg)"
        },
        "32%": {
            transform: "translate(-1px, 1px) rotate(3deg)"
        },
        "34%": {
            transform: "translate(5px, 1px) rotate(-3deg)"
        },
        "36%": {
            transform: "translate(1px, 5px) rotate(3deg)"
        },
        "38%": {
            transform: "translate(-1px, 1px) rotate(-3deg)"
        },
        "40%": {
            transform: "translate(-3px, 3px) rotate(-3deg)"
        },
        "42%": {
            transform: "translate(3px, 5px) rotate(-3deg)"
        },
        "44%": {
            transform: "translate(-1px, 1px) rotate(-3deg)"
        },
        "46%": {
            transform: "translate(5px, 3px) rotate(-3deg)"
        },
        "48%": {
            transform: "translate(-3px, 5px) rotate(3deg)"
        },
        "50%": {
            transform: "translate(-1px, -3px) rotate(-3deg)"
        },
        "52%": {
            transform: "translate(-3px, 3px) rotate(3deg)"
        },
        "54%": {
            transform: "translate(-3px, -3px) rotate(-3deg)"
        },
        "56%": {
            transform: "translate(5px, 3px) rotate(3deg)"
        },
        "58%": {
            transform: "translate(-3px, -3px) rotate(3deg)"
        },
        "60%": {
            transform: "translate(3px, 1px) rotate(3deg)"
        },
        "62%": {
            transform: "translate(-1px, -3px) rotate(3deg)"
        },
        "64%": {
            transform: "translate(1px, 3px) rotate(-3deg)"
        },
        "66%": {
            transform: "translate(-1px, 5px) rotate(3deg)"
        },
        "68%": {
            transform: "translate(5px, 5px) rotate(3deg)"
        },
        "70%": {
            transform: "translate(3px, -3px) rotate(3deg)"
        },
        "72%": {
            transform: "translate(1px, 5px) rotate(-3deg)"
        },
        "74%": {
            transform: "translate(1px, -3px) rotate(3deg)"
        },
        "76%": {
            transform: "translate(-1px, 1px) rotate(3deg)"
        },
        "78%": {
            transform: "translate(3px, -1px) rotate(3deg)"
        },
        "80%": {
            transform: "translate(-1px, 5px) rotate(-3deg)"
        },
        "82%": {
            transform: "translate(-3px, 1px) rotate(3deg)"
        },
        "84%": {
            transform: "translate(-3px, 5px) rotate(-3deg)"
        },
        "86%": {
            transform: "translate(3px, -1px) rotate(3deg)"
        },
       " 88%": {
            transform: "translate(-3px, 1px) rotate(3deg)"
        },
        "90%": {
            transform: "translate(1px, 1px) rotate(3deg)"
        },
        "92%": {
            transform: "translate(1px, -3px) rotate(-3deg)"
        },
        "94%": {
            transform: "translate(1px, 1px) rotate(-3deg)"
        },
        "96%": {
            transform: "translate(5px, 5px) rotate(3deg)"
        },
        "98%": {
            transform: "translate(3px, 5px) rotate(3deg)"
        },
        "0%,100%": {
            transform: "translate(0, 0) rotate(0)"
        }
    },
	wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        minHeight: 400,
        margin: 0,
        padding: '0 0 50px 0 !important',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            minHeight: 0
        }
	},
    defaultBlock: {
        flexGrow: 1,
        backgroundColor: '#f1f1f1'
    },
    overlay: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gridColumn: '1/2',
        gridRow: '1/2',
        zIndex: 10,
        justifyContent: 'center',
        alignItems: 'center',
        '& .slider': {
            transition: 'transform 0.5s ease, opacity 0.1s ease !important'
        }
    },
    dotdImage: {
        display: 'none',
        width: '100%',
        maxWidth: 400,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 250,
            marginBottom: 0
        }
    },
    timer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& h2': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 0,
            fontSize: 200,
            lineHeight: '150px',
            color: '#B99354',
            fontFamily: 'Fenice, serif',
            fontWeight: 400,
            backgroundClip: 'text',
            '-webkit-text-fill-color': 'transparent',
            '-webkit-background-clip': 'text',
            backgroundColor: '#B99354',
            backgroundImage: 'linear-gradient(180deg, #f1f1f1, #a0a0a0)',
            '& span': {
                display: 'none',
                fontSize: 12,
                fontFamily: theme.typography.fontFamily
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 76,
                lineHeight: '76px'
            }
        }
    },
    timerAnimated: {
        '& h2': {
            animationName: '$shake',
            animationDuration: '800ms',
            animationTimingFunction: 'ease-in-out',
            animationIterationCount: 'infinite',
            '&:first-child': {
                animationDelay: '50ms'
            },
            '&:last-child': {
                animationDelay: '150ms'
            }
        }
    },
    minutesLeft: {
        marginTop: 30,
        color: '#ffffff',
        fontSize: 18,
        letterSpacing: 0.5,
        textAlign: 'center',
        transition: 'opacity 0.5s ease'
    },
    placeholder: {
        display: 'flex',
        gridColumn: '1/2',
        gridRow: '1/2',
        justifyContent: 'center',
        alignItems: 'center'
    },
    chosenProductPlaceholder: {
        display: 'flex',
        padding: '20px 0',
        '& .image': {
            display: 'flex',
            '& span': {
                flexGrow: 1,
                paddingTop: '96%',
                backgroundColor: '#e0e0e0'
            }
        },
        '& .content': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            '& .text1': {
                width: '60%',
                height: 48,
                marginBottom: 15,
                backgroundColor: '#e0e0e0',
                borderRadius: 2
            },
            '& .text2': {
                width: '100%',
                height: 32,
                marginBottom: 10,
                backgroundColor: '#e0e0e0',
                borderRadius: 2
            },
            '& .addToCart': {
                display: 'flex',
                alignItems: 'center',
                marginTop: 25,
                '& .button': {
                    marginRight: 20,
                    backgroundColor: '#e0e0e0 !important'
                }
            }
        }
    },
    reveal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: 0,
        padding: '14px 34px',
        color: '#000000',
        fontSize: 40,
        fontWeight: 400,
        letterSpacing: 1,
        fontFamily: 'Fenice, serif',
        borderRadius: 0,
        transform: 'translate(-50%, -50%) !important',
        backgroundColor: '#B99354',
        backgroundImage: 'linear-gradient(180deg, #f1f1f1, #a0a0a0)',
        '&:hover': {
            backgroundColor: '#B99354',
            backgroundImage: 'linear-gradient(180deg, #f1f1f1, #a0a0a0)',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '15px',
            maxWidth: 300,
            width: '80%',
            fontSize: 22
        }
    },
    fadeOut: {
        opacity: 0
    }
}, {name: 'Countdown'});

export default function Countdown({block, later}) {
	const CMSSettings = getCMSSettings(block, 10);
    const {t} = useTranslation();
    const displaySettings = getCMSSlot('displaySettings', block.slots);
	const classes = useStyles();
    const countdownDate = moment(later.toString());
    const [timer, setTimer] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
    });
    const [isPastCountdown, setIsPastCountdown] = useState(false);
    const [isAnimating, setAnimating] = useState(false);
    const blockType = block.type;

    useEffect(() => {
        window.requestAnimationFrame(() => {
            getTimerFromDate();
        });
    }, []);

    useEffect(() => {
        if(!isPastCountdown) {
            let result = checkIsPastCountdown();

            if(result) {
                setIsPastCountdown(true);
            }
        }

        if(timer.seconds > 0) {
            if (parseInt(timer.hours) === 0 && parseInt(timer.minutes) === 0 && parseInt(timer.seconds) < 30 && parseInt(timer.seconds) > 0) {
                setAnimating(true);
            }
        }
    }, [timer]);

    function isCountdownWithinTimeframe() {
        const now = moment(new Date()).tz('Europe/Stockholm');
        return countdownDate.diff(now, 'hours') < 1;
    }

    function checkIsPastCountdown() {
        const now = moment(new Date());

        return now > countdownDate;
    }

    function leadingZeroes(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    function getTimerFromDate() {
        const now = moment(new Date());
        let hours = countdownDate.diff(now, 'hours') % (24 * 60 * 60);
        let minutes = countdownDate.diff(now, 'minutes') % 60;
        let seconds = countdownDate.diff(now, 'seconds') % 60;
        let hundredth = countdownDate.diff(now, 'hundredth') % 100;

        setTimer((timer) => {
            return {
                hours: leadingZeroes(hours, 2),
                minutes: leadingZeroes(minutes, 2),
                seconds: leadingZeroes(seconds, 2),
                hundredth: hundredth
            }
        });

        if(now < countdownDate) {
            window.requestAnimationFrame(getTimerFromDate);
        } else {
            setTimer((timer) => {
                return {
                    hours: leadingZeroes(0, 2),
                    minutes: leadingZeroes(0, 2),
                    seconds: leadingZeroes(0, 2),
                    hundredth: leadingZeroes(0, 2)
                }
            });
        }
    }

    function renderPlaceholder() {
        switch (blockType) {
            case 'newport-chosen-product':
                let slot = getCMSSlot('chosenProduct', block.slots);
                return (
                    <Grid className={[classes.chosenProductPlaceholder, slot.config.direction.value === true ? 'reverse' : ''].join(' ')} spacing={4} container>
                        <Grid className={'image'} xs={12} md={6} item>
                            <span></span>
                        </Grid>
                        <Grid className={'content'} xs={12} md={6} item>
                            <span className={'text1'}></span>
                            <span className={'text2'}></span>
                            <span className={'text2'}></span>
                            <div className={'addToCart'}>
                                <Button className={'button'} disabled={true} style={{width: 190, minHeight: 56, borderRadius: 50}}></Button>
                                <span className={'text2'} style={{width: 150, marginBottom: 0}}></span>
                            </div>
                        </Grid>
                    </Grid>
                );
            default:
                return (
                    <div className={classes.defaultBlock}></div>
                );
        }
    }

    function triggerPageRefresh() {
        window.dispatchEvent(new Event('trigger-page-refresh'));
    }

	return (
        isCountdownWithinTimeframe() ?
            <div className={[classes.wrapper, block.cssClass].join(' ')} style={{
				...CMSSettings.styles,
			}}>
                <div className={classes.overlay}>
                    <Slide class={['slider', isPastCountdown ? classes.fadeOut : ''].join(' ')} appear={true} direction="down" in={!isPastCountdown}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <h2 className={classes.dotdImage}>
                                <img alt={t('general:deal-of-the-day')} src={'https://cdn.newporthome.eu/media/54/50/bb/1697013755/deal-of-the-day-logo-liggande.svg'}/>
                            </h2>
                            <div className={[classes.timer, isAnimating ? classes.timerAnimated : ''].join(' ')}>
                                <h2>
                                    {timer.hours}:
                                    <span>{t('countdown-hours')}</span>
                                </h2>
                                <h2>
                                    {timer.minutes}:
                                    <span>{t('countdown-minutes')}</span>
                                </h2>
                                <h2>
                                    {timer.seconds}
                                    <span>{t('countdown-seconds')}</span>
                                </h2>
                            </div>
                        </div>
                    </Slide>

                    <Slide appear={true} direction="up" in={!isPastCountdown}>
                        <p className={['slider', classes.minutesLeft, isPastCountdown ? classes.fadeOut : ''].join(' ')} dangerouslySetInnerHTML={{__html: t('countdown-minutes-left')}}></p>
                    </Slide>

                    <Grow appear={false} in={isPastCountdown} {...(isPastCountdown ? { timeout: 1000 } : {})}>
                        <Button className={classes.reveal} onClick={() => triggerPageRefresh()}>{t('countdown-reveal')}</Button>
                    </Grow>
                </div>
            </div> : ''
	);
}
