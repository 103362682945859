import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import TextContent from "../text/text-content";
import {getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
	content: {

	},
	item: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important'
	}
}, {name: 'TextThreeColumns'});

export default function TextThreeColumns({slots, block}) {
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const left = getCMSSlot('left', slots);
	const center = getCMSSlot('center', slots);
	const right = getCMSSlot('right', slots);

	return (
		<Grid spacing={4} style={{
				...CMSSettings.styles,
			}} container>
			<Grid xs={12} md={4} classes={{root: classes.item}} item>
				<TextContent content={left.translated.config.content.value}/>
			</Grid>

			<Grid xs={12} md={4} classes={{root: classes.item}} item>
				<TextContent content={center.translated.config.content.value}/>
			</Grid>

			<Grid xs={12} md={4} classes={{root: classes.item}} item>
				<TextContent content={right.translated.config.content.value}/>
			</Grid>
		</Grid>
	);
}
