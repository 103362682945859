import React, { useContext } from 'react';
import Link from "next/link";
import { Button } from "@material-ui/core";
import { SessionContext } from "../../../providers/session";

export default function SimpleButton({ config, styles, buttonClass }) {
    const { config: { myNewport } } = useContext(SessionContext);

    function handleMyNewportLinks(content) {
		if(content && myNewport) {
			return content.replaceAll('|myNewport|', myNewport);
		}

		return content;
	}

    function renderButton() {
        return (
            <Button
                className={`${buttonClass} btn`}
                style={{ ...styles, textTransform: config.textUppercase.value ? 'uppercase' : 'none' }}
            >
                {config.title.value}
            </Button>
        );
    }

    return (
        config.newTab.value ?
            <a
                style={{ textDecoration: 'none', color: 'inherit' }}
                href={handleMyNewportLinks(config.url.value)}
                target={'_blank'}
            >
                {renderButton()}
            </a>
        :
            <Link href={handleMyNewportLinks(config.url.value)}>
                <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    {renderButton()}
                </a>
            </Link>
    );
}
