import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import Slider from "react-slick";
import {getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import Image from 'next/image';
import Lightbox from "../../../page/product/gallery/lightbox";
import ChevronRight from "../../../../public/images/icons/chevron_right_light.svg";
import ChevronLeft from "../../../../public/images/icons/chevron_left_light.svg";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const columnWidth = { lg: '33.333%', md: '50%', sm: '100%' };
const gutter = 20;

const useStyles = makeStyles({
	wrapper: {
		[theme.breakpoints.down('xs')]: {
			marginLeft: -15,
			marginRight: -15,
		}
	},
	slider: {
		position: 'relative',
		width: '100%',
		paddingBottom: 20,
		'& .slick-track': {
			display: 'flex',
			height: '100%',
			alignItems: 'center'
		},
		'& .slick-slide': {
			display: 'flex',
			height: '100%',
			cursor: 'pointer',
			'& > div': {
				display: 'flex',
				width: '100%',
				'& > div': {
					width: '100%'
				}
			}
		},
		'& .slick-list': {
			maxWidth: '100vw',
			overflow: 'hidden'
		},
		[theme.breakpoints.down('sm')]: {
			'& .slick-slider': {
				paddingBottom: 12
			},
			'& .slick-slide': {
				padding: 0
			},
			'& .slick-list': {
				marginBottom: 20
			}
		},
		'&.lightbox': {
			height: '100%',
			'& .slick-slider': {
				display: 'flex',
    			flexDirection: 'column',
				justifyContent: 'center',
    			height: '100%'
			},
			'& .slick-slide > div > div': {
				height: '100%'
			}
		}
	},
	sizeWrapper: {
		position: 'relative',
	},
	arrow: {
		position: 'absolute',
		bottom: 0,
		zIndex: 10,
		minWidth: 0,
		[theme.breakpoints.up('sm')]: {
			top: '50%',
			bottom: 'auto',
			width: 'auto',
			padding: 10,
			transform: 'translateY(-50%)',
			backgroundColor: theme.palette.primary.background,
			boxShadow: theme.boxShadow[1],
			borderRadius: '50%',
			'&:hover': {
				backgroundColor: theme.palette.primary.background,
				boxShadow: theme.boxShadow[2],
			}
		}
	},
	arrowRight: {
		right: 0,
		[theme.breakpoints.up('sm')]: {
			transform: 'translateY(-20px)',
			right: 15
		}
	},
	arrowLeft: {
		left: 0,
		[theme.breakpoints.up('sm')]: {
			transform: 'translateY(-20px)',
			left: 15
		}
	},
	arrowIcon: {
		display: 'block'
	},
	slickDots: {
		display: 'flex !important',
		justifyContent: 'center',
		padding: 0,
		listStyle: 'none',
		'& li': {
			width: 6,
			height: 6,
			margin: '2px 4px',
			borderRadius: '50%',
			backgroundColor: 'rgba(0,0,0,0.25)',
			'&.slick-active': {
				width: 6,
				height: 6,
				backgroundColor: 'rgba(0,0,0,0.75)'
			},
			'& button': {
				display: 'none'
			}
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
			marginBottom: -4
		}
	},
	masonryGridSizer: {
		width: `calc(${columnWidth.lg} - ${(gutter * 2) / 3}px)`,
		[theme.breakpoints.down('md')]: {
			width: `calc(${columnWidth.lg} - ${(gutter * 2) / 3}px)`
		},
		[theme.breakpoints.down('sm')]: {
			width: `calc(${columnWidth.md} - ${(10) / 2}px)`
		}
	},
	masonryGridItem: {
		marginBottom: '15px',
		width: `calc(${columnWidth.lg} - ${(gutter * 2) / 3}px)`,
		[theme.breakpoints.down('md')]: {
			width: `calc(${columnWidth.lg} - ${(gutter * 2) / 3}px)`
		},
		[theme.breakpoints.down('sm')]: {
			width: `calc(${columnWidth.md} - ${(10) / 2}px)`
		},
		'& img': {
			cursor: 'pointer',
			transition: 'transform 0.2s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	}
}, {name: 'SWImageSliderLightbox'});

export default function SWImageSliderLightbox({block, slots}) {
	const CMSSettings = getCMSSettings(block);
	const classes = useStyles();
	const slider = getCMSSlot('image-slider-lightbox', slots);
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const sliderConfig = slider.translated.config;
	const [open, setOpen] = useState(false);
	const [sliderIndex, setSliderIndex] = useState(0);
	const isGrid = sliderConfig?.grid?.value || false;
	const masonryGrid = useRef();

	useEffect(() => {
		if(isGrid) {
			const Masonry = require('masonry-layout');
			const masonry = new Masonry(masonryGrid.current, {
				columnWidth: '.masonry-grid-sizer',
				itemSelector: '.masonry-grid-item',
				percentPosition: true,
				horizontalOrder: false,
				gutter: isMobile ? 10 : 20
			});
			masonry.reloadItems();
			masonry.layout();
		}
	}, [masonryGrid.current]);

	const settings = {
		dots: true,
		infinite: true,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <Button classes={{root: [classes.arrow, classes.arrowRight].join(' ')}}><ChevronRight width={28} className={classes.arrowIcon}/></Button>,
		prevArrow: <Button classes={{root: [classes.arrow, classes.arrowLeft].join(' ')}}><ChevronLeft width={28} className={classes.arrowIcon}/></Button>,
		dotsClass: classes.slickDots
	};

	function getImage(image, lightbox = false, index) {
		let media = image.media;

		let imageObject = {
			src: media.url
		};

		if(lightbox) {
			imageObject.layout = 'responsive';
			imageObject.width = media.metaData.width;
			imageObject.height = media.metaData.height;
			imageObject.objectFit = 'contain';
		} else {
			imageObject.layout = 'responsive';
			imageObject.width = media.metaData.width;
			imageObject.height = media.metaData.height;
		}

		return <Image {...imageObject} className={[classes.image, 'image'].join(' ')} onClick={ () => {
			if(index) {
				setSliderIndex(index);
			}

			setOpen(true);
		}} style={{height: '100%'}} unoptimized/>
	}

	function onSlickChange(value) {
		setSliderIndex(value);
	}

	return (
		<div className={[classes.wrapper].join(' ')} style={{
				...CMSSettings.styles,
			}}>
			<div className={classes.sizeWrapper} style={{width: sliderConfig.width.value, height: sliderConfig.height.value}}>
				{
					isGrid ? <>
						<div ref={masonryGrid}>
							<div className={[classes.masonryGridSizer, 'masonry-grid-sizer'].join(' ')}/>
							{slider.data.sliderItems ? slider.data.sliderItems.map((image, index) => <div className={[classes.masonryGridItem, 'masonry-grid-item'].join(' ')}>{getImage(image, false, index)}</div>) : ''}
						</div>
					</> : <Slider className={classes.slider} {...settings} afterChange={onSlickChange}>
						{slider.data.sliderItems ? slider.data.sliderItems.map((image) => getImage(image)) : ''}
					</Slider>
				}
			</div>
			<Lightbox open={open} onClose={() => setOpen(false)}>
				<div className={[classes.slider, 'lightbox'].join(' ')}>
					<div className={classes.sizeWrapper} style={{display: 'flex', height: 'calc(100vh - 68px)'}}>
						<Slider {...settings} initialSlide={sliderIndex}>
							{slider.data.sliderItems ? slider.data.sliderItems.map((image) => getImage(image, true)) : ''}
						</Slider>
					</div>
				</div>
			</Lightbox>
		</div>
	);
}
