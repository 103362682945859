import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSSettings} from "tools/cmsBlockSettings";
import Image from 'next/image';
import Slick from "react-slick";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import Link from "next/link";
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";
import { useEffect, useRef } from "react";
import { preventTouch, touchStart } from "functions/touchHelpers";

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		position: 'relative',
		'& .slick-slider': {
			width: '100%',
			padding: '0 20px',
			overflow: 'hidden',
		},
		'& .slick-track': {
			display: 'flex',
			gap: 20,
			transform: 'translate3d(0,0,0)'
		},
		'& .slick-slide': {
			display: 'flex',
			'& > div': {
				width: '100%'
			}
		},
		'& .slick-list': {
			marginBottom: 25,
			maxWidth: '100vw',
			transform: 'translate3d(0,0,0)'
		},
		[theme.breakpoints.down('sm')]: {
			'& .slick-slider': {
				padding: 0
			},
			'& .slick-slide': {
				padding: 0
			},
			'& .slick-list': {
				marginRight: '20%',
				marginBottom: 0
			}
		},
	},
	imageWrapper: {
		position: 'relative',
		background: theme.palette.primary.color,
		display: 'block',
	},
	content: {
		padding: '25px 20px 20px',
		backgroundColor: theme.palette.primary.background,
		textAlign: 'center'
	},
	title: {
		margin: 0,
		fontWeight: 400,
		fontSize: 24,
		color: theme.palette.primary.lowlight,
		fontFamily: 'Chronicle, serif'
	},
	text: {
		margin: 0,
		marginTop: 6,
		fontSize: 15,
		fontWeight: 400,
		color: theme.palette.text.grey,
		'& p': {
			margin: 0
		}
	},
	sliderItem: {
		display: 'flex',
		width: '100%'
	},
	sliderItemLink: {
		display: 'block',
		width: '100%',
		color: 'inherit',
		textDecoration: 'none'
	},
	arrow: {
		position: 'absolute',
		bottom: 0,
		zIndex: 10,
		minWidth: 0,
		backgroundColor: theme.palette.primary.background,
		boxShadow: '3px 3px 3px rgba(50, 50, 93, 0.04)',
		[theme.breakpoints.down('sm')]: {
			display: 'none !important'
		}
	},
	arrowRight: {
		right: 0,
	},
	arrowLeft: {
		left: 0,
	},
	arrowIcon: {
		display: 'block'
	},
	slickDots: {
		display: 'flex !important',
		justifyContent: 'center',
		padding: 0,
		listStyle: 'none',
		'& li': {
			width: 5,
			height: 5,
			margin: '2px 4px',
			borderRadius: '50%',
			backgroundColor: 'rgba(0,0,0,0.25)',
			'&.slick-active': {
				width: 6,
				height: 6,
				backgroundColor: 'rgba(0,0,0,0.75)'
			},
			'& button': {
				display: 'none'
			}
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none !important',
			margin: 0
		}
	}
}, {name: 'ImageTextSlider'});

export default function ImageTextSlider({block, slots, category}) {
	const router = useRouter();
	const classes = useStyles();
	const elem = useRef();
	const CMSSettings = getCMSSettings(block);

	const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
		<span {...props}>{children}</span>
	);

	const settings = {
		dots: true,
		infinite: true,
		speed: 200,
		autoplay: false,
  		autoplaySpeed: 2000,
		slidesToShow: 3,
		slidesToScroll: 1,
		nextArrow: (
			<SlickButtonFix>
				<Button classes={{root: [classes.arrow, classes.arrowRight].join(' ')}}><ChevronRight classes={{root: classes.arrowIcon}}/></Button>
			</SlickButtonFix>
		),
		prevArrow: (
			<SlickButtonFix>
				<Button classes={{root: [classes.arrow, classes.arrowLeft].join(' ')}}><ChevronLeft classes={{root: classes.arrowIcon}}/></Button>
			</SlickButtonFix>
		),
		dotsClass: classes.slickDots,
		responsive: [
			{
				breakpoint: theme.breakpoints.values.lg,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: theme.breakpoints.values.md,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					center: false,
					dots: false
				}
			},
			{
				breakpoint: theme.breakpoints.values.sm,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					center: false,
					dots: false
				}
			}
		]
	};

	useEffect(() => {
		if(elem.current) {
			elem.current.addEventListener('touchstart', touchStart);
			elem.current.addEventListener('touchmove', preventTouch, { passive: false });
		}

		return () => {
			if(elem.current) {
				elem.current.removeEventListener('touchstart', touchStart);
				elem.current.removeEventListener('touchmove', preventTouch, { passive: false });
			}
		}
	}, [elem]);

	function getImage(slot) {
		let media = slot.data.media;

		if(!media) {
			return {
				src: ' ',
				alt: slot.translated?.config?.title?.value || category.metaTitle || category.name || 'Newport image',
				width: 0,
				height: 0
			}
		}

		let returnObject = {
			src: media.url,
			alt: slot.translated?.config?.title?.value || category.metaTitle || category.name || 'Newport image',
		};

		returnObject.layout = 'responsive';
		returnObject.width = media.metaData.width;
		returnObject.height = media.metaData.height;

		return returnObject;
	}

	function getActiveSliders() {
		return slots.filter((slide) => {
			if(slide.type === 'display-settings') {
				return false;
			}

			return slide.config.active.value !== false;
		});
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	return (
		<Grid style={{
				...CMSSettings.styles,
			}} container>
			<Grid xs={12} className={classes.wrapper} ref={elem} item>
					<Slick {...settings}>
						{getActiveSliders().map((slide, key) => {
							return (
								<div className={classes.sliderItem} key={slide.blockId + '-' + key}>
									<Link href={urlNormalize(slide.translated.config.url.value)}>
										<a className={classes.sliderItemLink} key={slide.blockId}>
											<div className={classes.imageWrapper}>
												<Image {...getImage(slide)} className={[classes.image, 'image'].join(' ')} loading={'lazy'} unoptimized/>
											</div>
											<div className={classes.content}>
												<h4 className={classes.title}>{slide.translated.config.title.value}</h4>
												{slide.translated.config.description.value ? <div className={classes.text} dangerouslySetInnerHTML={{__html: slide.translated.config.description.value}}/> : ''}
											</div>
										</a>
									</Link>
								</div>
							)
						})}
					</Slick>
			</Grid>
		</Grid>
	);
}
