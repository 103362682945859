import { makeStyles } from '@material-ui/styles';
import { getButtonSettings, getCMSSlot } from "tools/cmsBlockSettings";
import React from 'react';
import SimpleButton from './simple-button';
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        '& .btn': {
            color: theme.palette.primary.background,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px 30px 15px 30px',
            fontSize: '14px',
            background: theme.palette.primary.color,
            fontWeight: '600',
            borderRadius: '0px',
            letterSpacing: '0.5px',
            textDecoration: 'none',
            [theme.breakpoints.down('sm')]: {
                fontWeight: 400,
                letterSpacing: 0
            }
        },
    },
    button: {
        width: '100%'
    }
}), { name: 'CmsButton' });

export default function ButtonGrid({ slots, block }) {
    const classes = useStyles();
    const button1 = getCMSSlot('button1', slots);
    const Button1Settings = getButtonSettings(block, button1.config);
    const button2 = getCMSSlot('button2', slots);
    const Button2Settings = getButtonSettings(block, button2.config);
    const button3 = getCMSSlot('button3', slots);
    const Button3Settings = getButtonSettings(block, button3.config);
    const button4 = getCMSSlot('button4', slots);
    const Button4Settings = getButtonSettings(block, button4.config);

    return (
        <div
            style={{
                ...Button1Settings.styles.wrapper,
            }}
            className={classes.wrapper}
        >
            <Grid spacing={1} container>
                <Grid xs={6} sm={3} style={{display: button1.config.title.value ? '' : 'none'}} item>
                    <SimpleButton config={button1.config} styles={Button1Settings.styles.button}
                          buttonClass={[Button1Settings.class, classes.button].join(' ')}/>
                </Grid>

                <Grid xs={6} sm={3} style={{display: button2.config.title.value ? '' : 'none'}} item>
                    <SimpleButton config={button2.config} styles={Button2Settings.styles.button}
                          buttonClass={[Button2Settings.class, classes.button].join(' ')}/>
                </Grid>

                <Grid xs={6} sm={3} style={{display: button3.config.title.value ? '' : 'none'}} item>
                    <SimpleButton config={button3.config} styles={Button3Settings.styles.button}
                          buttonClass={[Button3Settings.class, classes.button].join(' ')}/>
                </Grid>

                <Grid xs={6} sm={3} style={{display: button4.config.title.value ? '' : 'none'}} item>
                    <SimpleButton config={button4.config} styles={Button4Settings.styles.button}
                          buttonClass={[Button4Settings.class, classes.button].join(' ')}/>
                </Grid>
            </Grid>
        </div>
    );
}
