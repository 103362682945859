import {makeStyles} from '@material-ui/styles';
import theme from "../../../theme";
import {useTranslation} from "../../../../tools/i18n";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useRouter} from "next/router";
import DropdownIndicator from "../../../../public/images/icons/drop-down.svg";
import Filter from "../../../../public/images/icons/filter.svg";
import ProductSorting from '../product-listing/sorting';
import { getNewSortingParams, updateRouterData } from '../../../../functions/plpOps';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(
	(theme) => ({
		wrapper: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			gap: '10px',
			padding: 0,
			[theme.breakpoints.up('lg')]: {
				'@media screen and (min-width: 1296px)': {
					'& > button': {
						content: '""',
						maxWidth: 300,
						flexShrink: 0
					}
				}
			},
			'& .desktop': {
				position: 'relative !important',
				left: 'unset !important',
				top: 'unset',
			},
			'& .mobile': {
				position: 'relative !important',
				left: 'unset !important',
				top: 'unset',
			},
			'& > div': {
				padding: 0,
				width: '50%',
				maxWidth: '400px',
			},
			'& > div > div': {
				padding: 0,
				width: '100% !important'
			},
			'& > div > div > div': {
				width: '100% !important',
				maxWidth: '300px',
			},
			'& > div > div > button': {
				width: '100% !important',
				maxWidth: '300px',
			}
		},
		filterTrigger: {
			display: 'flex',
			alignItems: 'center',
			minHeight: '38px',
			width: '50%',
			maxWidth: '300px',
			padding: '2px 10px 2px 0',
			border: 'none',
			textAlign: 'left',
			whiteSpace: 'nowrap',
			...theme.typography.dropdown,
			backgroundColor: 'transparent',
			outline: 'none',
			cursor: 'pointer',
			'& svg': {
				display: 'inline-block',
				fill: 'currentColor',
				lineHeight: 1,
				stroke: 'currentColor',
				strokeWidth: 0,
				color: '#000',
				marginRight: '10px',

			},
			'.desktop &': {
				width: 'auto',
				padding: 0,
				boxShadow: 'none',
				'& svg': {
					marginRight: 10
				},
				'&.open': {
					'& svg': {
						transform: 'none'
					}
				},
			},
			'&.mobile': {
				minHeight: 0,
				padding: '6px 8px',
				border: '1px solid #666',
				lineHeight: 1.43,
				'& svg': {
					margin: 'auto 0 auto auto'
				}
			},
			'&.open': {
				'& svg': {
					transform: 'rotateZ(-90deg)'
				}
			},
		},
	}),
	{ name: 'InspirationPageFilters' }
);

export default function InspirationPageFilters({ block, slots, category, breadcrumbs }) {
	const router = useRouter();
	const classes = useStyles();
	const { query } = router;
	const { page, order, slug, ...filters } = query;
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const { t } = useTranslation(['general', 'attributes']);
	const [categorySlug, setCategorySlug] = useState('');
	const availableSortings = [
		{label: t('sort-by-topseller'), value: "topseller"},
		{label: t('sort-by-discount'), value: "best-discounts"},
		{label: t('sort-by-news'), value: "uutiset"},
		{label: t('sort-by-name-asc'), value: "name-asc"},
		{label: t('sort-by-name-desc'), value: "name-desc"},
		{label: t('sort-by-price-asc'), value: "price-asc"},
		{label: t('sort-by-price-desc'), value: "price-desc"}
	]

	useEffect(async() => {
		if (categorySlug.length < 1) {
			const slug = await getCategorySlug();
			setCategorySlug(slug || '')
		}
	}, []);

	async function getCategorySlug() {
		try {
			const filterCategoryId = block?.slots[0]?.config?.selectedCategoryId?.value;
			const resp = await fetch(`/api/category/get/by-id/${filterCategoryId}`)
			const cat = await resp.json()
			return cat?.category?.customFields?.prebuilt_slug
		} catch(e) {
			return ''
		}
	}

	function onSortChange(data) {
		updateUrlParams({ ...getNewSortingParams(page, query, data) });
	}
	function onFilterChange(data) {
		query.filter = 'open'
		updateUrlParams({ ...getNewSortingParams(page, query, data) });
	}

	function updateUrlParams({ page, order, slug, ...filters }) {
		const { routerData, replace } = updateRouterData({
			page,
			order,
			initialPage: '/',
			pathname: '/shop/' + categorySlug,
			filters,
			query,
		});

		if (replace) {
			router.replace(routerData, undefined, { scroll: true }).catch(e => console.log(e));
		} else {
			router.push(routerData, undefined, { scroll: true }).catch(e => console.log(e));
		}
	}

	return (
		<>
			<div className={classes.wrapper}>
				<button className={[classes.filterTrigger, isDesktop ? 'desktop' : 'mobile'].join(' ')} onClick={onFilterChange}>
					{isDesktop ?
						<>
							<Filter width={20} />
							{t('filter')}
						</>
						:
						<>
							{t('filter')}
							<DropdownIndicator width={12}/>
						</>

					}
				</button>

				<ProductSorting
					block={block}
					sortings={availableSortings}
					currentSorting={'topseller'}
					onSortChange={onSortChange}
				/>
			</div>

		</>
	)
}
