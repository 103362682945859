import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSConfig, getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import Image from 'next/image';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextContent from "../text/text-content";

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		alignItems: 'stretch',
		'&.blackweek-dotd': {
			'& .imageWrapper': {
				padding: 30,
				backgroundColor: 'transparent',
				[theme.breakpoints.up('md')]: {
					padding: 50,
				}
			}
		}
	},
	imageWrapper: {
		position: 'relative',
		background: theme.palette.primary.color,
		display: 'block'
	},
	text: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			marginTop: 40
		}
	},
	content: {
		margin: '0 auto',
		padding: '0 30px',
		width: '100%',
		maxWidth: 500,
		'& h2': {
			marginBottom: 10,
			lineHeight: 1
		},
		'.stores-text &': {
			'& h3': {
				fontFamily: theme.typography.h4.fontFamily,
				fontSize: 12,
    			fontWeight: 500,
    			textTransform: 'uppercase'
			},
			'& p': {
				margin: '5px 0 0 0',
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: 20,
				padding: 0,
			}
		},
		'.wide-content &': {
			maxWidth: 700
		}
	}
}, {name: 'ImageSideText'});

export default function ImageSideText({block, slots, category}) {
	const CMSSettings = getCMSSettings(block);
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const text = getCMSSlot('text', slots);
	const image = getCMSSlot('image', slots);
	const isRight = block.type === 'right-image-side-text';

	function getImage(slot) {
		let media = slot.data.media;
		let config = slot.translated.config;

		if(!media) {
			return {
				src: ' ',
				width: 0,
				height: 0
			}
		}

		let returnObject = {
			src: media.url
		};

		returnObject.layout = 'responsive';
		returnObject.width = media.metaData.width;
		returnObject.height = media.metaData.height;

		return returnObject;
	}

	function getOpacity(image) {
		return getCMSConfig('opacity', image.config);
	}

	return (
		<Grid style={{
				...CMSSettings.styles,
			}} container>
			<Grid xs={12} item>
				<Grid className={[classes.wrapper, CMSSettings.class].join(' ')} alignItems="center" direction={isRight && !isMobile ? 'row-reverse' : 'row'} container>
					<Grid xs={12} md={6} item>
						<div className={[classes.imageWrapper, 'imageWrapper'].join(' ')} style={{opacity: getOpacity(image)}}>
								<Image {...getImage(image)} alt={category.metaTitle || category.name || 'Newport Image'} className={[classes.image, 'image'].join(' ')} unoptimized/>
						</div>
					</Grid>
					<Grid className={classes.text} xs={12} md={6} item>
						<div className={classes.content} style={{alignSelf: text.config.verticalAlign.value}}>
							<TextContent content={text.data.content} isVariant={true}/>
						</div>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
}
