import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {getCMSConfig, getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import React from "react";

const useStyles = makeStyles({
	wrapper: {
		position: 'relative'
	},
	iframe: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
    	height: '100%',
		border: 'none'
	}
}, {name: 'Youtube'});

export default function Youtube({block, slots}) {
	const CMSSettings = getCMSSettings(block);
	const classes = useStyles();
	const video = getCMSSlot('video', slots);
	const aspectRatio = ((9 / 16) * 100) + '%';

	function getConfigString() {
		const configuration = [];
		const showControls = getCMSConfig('showControls', video.config) ? 'controls=0' : false;
		const autoplay = getCMSConfig('autoPlay', video.config) ? 'autoplay=1' : false;
		const loop = getCMSConfig('loop', video.config) ? 'loop=1' : false;

		if(showControls) {
			configuration.push(showControls);
		}

		if(autoplay) {
			configuration.push(autoplay);
		}

		if(loop) {
			configuration.push(loop);
		}

		return configuration.length > 0 ? '?' + configuration.join('&') : '';
	}

	function getVideoSrc() {
		const src = getCMSConfig('videoID', video.config);

		return src + getConfigString();
	}

	return (
		<Grid style={{
				...CMSSettings.styles,
			}} container>
			<Grid xs={12} item>
				<div className={classes.wrapper} style={{
					paddingBottom: aspectRatio
				}}>
					<iframe className={classes.iframe} src={getVideoSrc()}></iframe>
				</div>
			</Grid>
		</Grid>
	);
}
