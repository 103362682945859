import { makeStyles } from '@material-ui/styles';
import { getCMSSettings, getCMSSlot } from 'tools/cmsBlockSettings';
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField
} from '@material-ui/core';
import { useTranslation } from 'tools/i18n';
import { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import theme from '../../../theme';
import { useRouter } from 'next/router';

const useStyles = makeStyles(
	{
		wrapper: {
			display: 'flex',
			justifyContent: 'center',
			width: '100%'
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			gap: 20,
			width: '100%',
			maxWidth: 600
		},
		inputGroup: {
			display: 'flex',
			flexDirection: 'row',
			gap: 20,
			flexGrow: 1
		},
		input: {
			width: '100%',
			'& input': {
				padding: '14px 16px 16px',
				color: theme.palette.primary.lowlight,
				'&::-webkit-input-placeholder': {
					opacity: 1,
					fontWeight: 300
				},
				'.gold &': {
					color: '#c3a343'
				}
			},
			'& textarea': {
				'.gold &': {
					color: '#c3a343'
				}
			}
		},
		outline: {
			borderColor: '#cacaca',
			borderRadius: 3,
			'.gold &': {
				borderColor: '#c3a343 !important'
			}
		},
		label: {
			top: -3,
			'.gold &': {
				color: '#c3a343'
			}
		},
		helperText: {
			margin: 0,
			paddingLeft: 15
		},
		signUp: {
			...theme.button.purchase,
		backgroundColor: theme.palette.primary.purchase.background,
			maxWidth: 250,
			borderRadius: 3,
			'&:hover': {
				...theme.button.purchaseHover
			},
			'.gold &': {
				backgroundColor: 'transparent',
				border: '2px solid #c3a343',
				color: '#c3a343',
				'&:hover': {
					backgroundColor: 'rgba(195,163,67,0.08) !important',
				}
			}
		},
		conditions: {
			marginTop: 0,
			fontSize: 16,
			textAlign: 'center'
		}
	},
	{ name: 'RSVPForm' }
);

export default function RSVPForm({ slots, block }) {
	const classes = useStyles();
	const router = useRouter();
	const [formData, setFormDate] = useState({tag: ''});
	const [checkboxes, setCheckboxes] = useState({});
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const CMSSettings = getCMSSettings(block);
	const { t } = useTranslation(['support', 'errors']);
	const slot = getCMSSlot('rsvpSignup', slots);
	const addPlusOne = slot?.config?.addPlusOne?.value;

	// Check cssClass for influencer-flag
	const isInfluencer = CMSSettings.class === 'influencer';
	const isGold = CMSSettings.class === 'gold';

	useEffect(() => {
		const eclubData = getEclubData();
		updateFormData('email', {target: {value: eclubData.email}});

		if(isInfluencer) {
			setCheckboxes({...checkboxes, 'Stay for dinner': false});
		}
	}, []);

	function getEclubData() {
		return typeof localStorage !== 'undefined' && localStorage.getItem('eclubData') ? JSON.parse(localStorage.getItem('eclubData')) : false;
	}

	function updateFormData(input, event) {
		let value = event.target.value;
		let newFormData = { ...formData };
		newFormData[input] = value;

		setFormDate(newFormData);
	}

	function checkRequired() {
		const requiredFields = ['email'];
		let validation = true;


		requiredFields.forEach(requiredField => {
			let fieldValue = formData[requiredField];

			if (typeof fieldValue === 'undefined' || fieldValue.length === 0) {
				validation = false;
			}
		});

		return validation;
	}

	async function submit() {
		const eclubData = getEclubData();
		const eventDate = slot?.config?.eventDate?.value;
		const eventName = slot?.config?.eventName?.value;
		const eventStoreId = slot?.config?.eventStoreId?.value;

		if (!checkRequired()) {
			document.getElementById('rsvp-form').reportValidity();
			return;
		}

		try {
			setLoading(true);

			if(eclubData?.id) {
				formData.tag = formData.tag + ' | eclubId:' + eclubData.id;
			}

			let checkboxKeys = Object.keys(checkboxes);
			if(checkboxKeys.length > 0) {
				formData.tag = formData.tag + ' | ' + checkboxKeys.map((key) => {
					return key + ': ' + (checkboxes[key] ? 'Ja' : 'Nej');
				});
			}

			formData.tag = formData.tag + ` | link:${router.asPath.split('?')[0]}`;
			formData.domain = window.location.host;
			formData.url = window.location.pathname;

			if(isInfluencer) {
				formData.recipients = ['influencer'];
			}

			if (eventDate) {
				formData.event_date = eventDate;
			}

			if (eventName) {
				formData.event_name = eventName;
			}

			if (eventStoreId) {
				formData.event_store_id = eventStoreId;
			}

			let response = await fetch('/api/newport/rsvp', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			let rsvpData = await response.json();

			if (rsvpData && rsvpData.status === 'success') {
				setSuccess(true);
			} else {
				setError(t('errors:signup-general-error'));
			}

			setLoading(false);
		} catch (e) {
			setError(t('errors:signup-general-error'));

			setLoading(false);
		}
	}

	function addPlusOneBlock(show) {
		if (!show) {
			return null;
		}

		return <Grid className={classes.inputGroup} item>
			<TextField
				className={classes.input}
				InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
				InputLabelProps={{ className: classes.label }}
				onChange={event => {
					updateFormData('name2', event);
				}}
				type="text"
				label={'Person 2'}
				variant="outlined"
			/>
		</Grid>
	}

	return (
		<form
			id={'rsvp-form'}
			className={[classes.wrapper, CMSSettings.class].join(' ')}
			style={{
				...CMSSettings.styles
			}}
		>
			{!success ? (
				<Grid className={classes.form} container>
					{!isInfluencer ? <>
						{!isGold ? <Grid className={classes.inputGroup} item>
							<TextField
								className={classes.input}
								InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
								InputLabelProps={{ className: classes.label }}
								onChange={event => {
									updateFormData('name1', event);
								}}
								type="text"
								label={'Person 1'}
								variant="outlined"
								required
							/>
						</Grid> : ''}

						{addPlusOneBlock(addPlusOne)}
					</> : ''}

					<Grid className={classes.inputGroup} item>
						<TextField
							className={classes.input}
							InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
							InputLabelProps={{ className: classes.label }}
							onChange={event => {
								console.log(event);
								updateFormData('email', event);
							}}
							value={formData.email}
							type="text"
							label={t('support-email')}
							variant="outlined"
							required
						/>
					</Grid>

					{isInfluencer ?
						<Grid className={classes.inputGroup} item>
							<RadioGroup aria-label="gender" name="stayForDinne" value={checkboxes['Stay for dinner'] || false} onChange={event => {
								setCheckboxes({...checkboxes, 'Stay for dinner': event.target.value === "true"});
							}}>
								<FormControlLabel
									value={false}
									control={<Radio/>}
									label="Event"
								/>
								<FormControlLabel
									value={true}
									control={<Radio/>}
									label="Event + Middag"
								/>
							</RadioGroup>
						</Grid>
					: ''}

					<Grid className={classes.inputGroup} item>
						<TextField
							className={classes.input}
							InputProps={{ disableUnderline: true, classes: { notchedOutline: classes.outline } }}
							InputLabelProps={{ className: classes.label }}
							onChange={event => {
								updateFormData('message', event);
							}}
							type="text"
							label={isInfluencer ? 'Matallergier / specialkost' : t('support-message')}
							variant="outlined"
							rows={4}
							multiline
						/>
					</Grid>

					{error ? (
						<Grid item>
							<Alert severity="error" style={{ marginBottom: -20 }}>
								<span dangerouslySetInnerHTML={{ __html: error }} />
							</Alert>
						</Grid>
					) : (
						''
					)}

					<Grid className={classes.inputGroup} style={{ justifyContent: 'center', marginTop: 20 }} item>
						<Button
							className={classes.signUp}
							onClick={() => {
								submit();
							}}
						>
							Anmäl{' '}
							{loading ? (
								<CircularProgress size={14} color={'inherit'} style={{ marginLeft: 8 }} disableShrink />
							) : (
								''
							)}
						</Button>
					</Grid>
				</Grid>
			) : (
				''
			)}

			{success ? <Alert severity={'success'}>Tack för din anmälan!</Alert> : ''}
		</form>
	);
}
