import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import Image from 'next/image';
import TextContent from "../text/text-content";
import React from "react";
import Link from "next/link";
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		padding: '0 10px'
	},
	imageWrapper: {
		position: 'relative',
		display: 'block'
	},
	link: {
		width: '100%',
		color: 'inherit',
		textDecoration: 'none',
		'&:hover': {
			backgroundColor: '#fafafa'
		}
	},
	content: {
		position: 'relative',
		textAlign: 'center',
		color: 'inherit',
		'& h2': {
			marginBottom: 0,
			...theme.typography.h2
		}
	},
	button: {
		marginTop: 0,
		marginBottom: 10,
		textTransform: 'none',
		textDecoration: 'underline',
		fontWeight: 600,
		'&:hover': {
			backgroundColor: 'transparent',
			textDecoration: 'underline'
		}
	},
	percentage: {
		position: 'absolute',
		top: 15,
		right: 15,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 50,
		height: 50,
		fontSize: 16,
		color: theme.palette.primary.background,
		backgroundColor: theme.palette.primary.color,
		borderRadius: '50%'
	}
}, {name: 'campaign-entry'});

export default function CampaignEntry({block, slots, category}) {
	const router = useRouter();
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const slot = getCMSSlot('campaignEntry', slots);
	const config = slot.translated.config;

	function getImage(slot) {
		let media = slot.data.media;

		if(!media) {
			return {
				src: ' ',
				width: 0,
				height: 0
			}
		}

		let returnObject = {
			src: media.url
		};

		returnObject.layout = 'responsive';
		returnObject.width = media.metaData.width;
		returnObject.height = media.metaData.height;

		return returnObject;
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	return (
		<>
			{config.active.value === "" || config.active.value !== false ?
				<Grid style={{
					...CMSSettings.styles,
				}} container>
					<Grid xs={12} item>
						<Grid className={classes.wrapper} alignItems="center" container>
							<Link href={urlNormalize(config.url.value)}>
								<a className={classes.link}>
									<Grid xs={12} item>
										<div className={classes.imageWrapper}>
											<Image alt={category.metaTitle || category.name || 'Newport image'} {...getImage(slot)} className={[classes.image, 'image'].join(' ')}
												   unoptimized/>
											<span className={classes.percentage}>{config.percentage.value}%</span>
										</div>
									</Grid>
									<Grid xs={12} item>
										<div className={classes.content}>
											<h2>{config.title.value}</h2>
											<TextContent content={config.description.value}/>
											<Button className={classes.button}>{config.button.value}</Button>
										</div>
									</Grid>
								</a>
							</Link>
						</Grid>
					</Grid>
				</Grid> : ''
			}
		</>
	);
}
