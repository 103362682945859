	export function touchStart(e){
		this.firstClientX = e.touches[0].clientX;
		this.firstClientY = e.touches[0].clientY;
	}

	export function preventTouch(e){
		const minValue = 5; // threshold

		this.clientX = e.touches[0].clientX - this.firstClientX;
		this.clientY = e.touches[0].clientY - this.firstClientY;

		// Vertical scrolling does not work when you start swiping horizontally.
		if(Math.abs(this.clientX) > minValue){
			e.preventDefault();
			e.returnValue = false;
			return false;
		}
	}